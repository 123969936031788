import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <VerifyLoginOTP />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: 'printful',
          children: [
            { element: <Navigate to="/dashboard/printful/auth" replace />, index: true },
            { path: 'auth', element: <PrintfulAuth /> },
            { path: 'success', element: <PrintfulAuthSuccess /> },
            { path: 'products', element: <PrintfulProducts /> },
            { path: 'product/detail/:id', element: <PrintProductDetails /> },
          ],
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: 'list', element: <ProductsList /> },
            { path: 'create', element: <ProductsCreate /> },
            { path: 'edit/:id', element: <ProductsCreate /> },
            { path: 'details/:id', element: <ProductsDetails /> },
          ],
        },
        {
          path: 'service',
          children: [
            { path: 'create', element: <ServiceCreate /> },
            { path: 'edit/:id', element: <ServiceCreate /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryList /> },
            { path: 'create', element: <CategoryCreate /> },
            { path: 'edit/:id', element: <CategoryCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:id', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
            { path: 'edit/:id', element: <BlogNewPost /> },

            { path: 'myPosts', element: <MyBlogPosts /> },
          ],
        },
        {
          path: 'Subscriptions',
          children: [
            { element: <Navigate to="/dashboard/Subscriptions/list" replace />, index: true },
            { path: 'list', element: <SubscriptionList /> },
            { path: 'Create', element: <SubscriptionCreate /> },
          ],
        },
        {
          path: 'brands',
          children: [
            { element: <Navigate to="/dashboard/brands/list" replace />, index: true },
            { path: 'list', element: <BrandsList /> },
            { path: 'create', element: <BrandsCreate /> },
            { path: 'edit/:id', element: <BrandsCreate /> },
          ],
        },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/list" replace />, index: true },
            { path: 'list', element: <OrderList /> },
            { path: 'create', element: <OrderCreate /> },
            { path: 'edit/:id', element: <OrderCreate /> },
            { path: 'detail/:id', element: <OrderDetails /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
            // { path: 'profile', element: <UserProfile /> },
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <Navigate to="/dashboard/chat/list" replace />, index: true },
            { path: 'list', element: <Chat /> },
            { path: 'message/:id', element: <ChatBox /> },
          ],
        },

        {
          path: 'notification',
          children: [
            { element: <Navigate to="/dashboard/notification/list" replace />, index: true },
            { path: 'list', element: <Notification /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VerifyLoginOTP = Loadable(lazy(() => import('../pages/auth/VerifyOTP')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Products
const ProductsList = Loadable(lazy(() => import('../pages/products/List')));
const ProductsCreate = Loadable(lazy(() => import('../pages/products/Create')));
const ProductsDetails = Loadable(lazy(() => import('../pages/products/Details')));

const ServiceCreate = Loadable(lazy(() => import('../pages/service/Create')));
// Category
const CategoryList = Loadable(lazy(() => import('../pages/category/List')));
const CategoryCreate = Loadable(lazy(() => import('../pages/category/Create')));

// Brands
const BrandsList = Loadable(lazy(() => import('../pages/brands/List')));
const BrandsCreate = Loadable(lazy(() => import('../pages/brands/Create')));
// Order
const OrderList = Loadable(lazy(() => import('../pages/order/List')));
const OrderCreate = Loadable(lazy(() => import('../pages/order/Create')));
const OrderDetails = Loadable(lazy(() => import('../pages/order/Details')));

// User
const UserAccount = Loadable(lazy(() => import('../pages/user/UserAccount')));
const UserProfile = Loadable(lazy(() => import('../pages/user/UserProfile')));

// Subscriptionsription
const SubscriptionList = Loadable(lazy(() => import('../pages/Subscription/List')));
const SubscriptionCreate = Loadable(lazy(() => import('../pages/Subscription/Create')));

// Chat
const Chat = Loadable(lazy(() => import('../pages/chat/index')));
const ChatBox = Loadable(lazy(() => import('../pages/chat/ChatBox')));

// notification
const Notification = Loadable(lazy(() => import('../pages/Notification/index')));

// Blog
const BlogPosts = Loadable(lazy(() => import('../pages/blogs/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/blogs/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/blogs/BlogNewPost')));
const MyBlogPosts = Loadable(lazy(() => import('../pages/blogs/MyBlogPosts')));

// printful
const PrintfulAuth = Loadable(lazy(() => import('../pages/Printfulproducts/PrintfulAuth')));
const PrintfulAuthSuccess = Loadable(lazy(() => import('../pages/Printfulproducts/PrintFulAuthSuccess')));
const PrintfulProducts = Loadable(lazy(() => import('../pages/Printfulproducts/List')));
const PrintProductDetails = Loadable(lazy(() => import('../pages/Printfulproducts/PrintProductDetails')));
