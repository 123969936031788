import { Add, Remove } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTransition } from 'react';
import { CirclePicker } from 'react-color';

import { editShape, removeShape } from '../../../redux/slices/studio';
import { useDispatch, useSelector } from '../../../redux/store';

export function EditShapePanel({ shape }) {
  const [isPending, startTransition] = useTransition();
  const dispatch = useDispatch();

  const canvasId = useSelector((state) => state.studio.selectedView.type);

  return (
    <Accordion>
      <AccordionSummary>
        <ListItem disableGutters>
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={shape?.type} />
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(removeShape({ shapeId: shape?.id, canvasId }));
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        {shape?.type === 'text' && (
          <Box>
            <TextField
              label="Edit text"
              multiline
              rows={2}
              variant="outlined"
              fullWidth
              value={shape?.text || ''}
              onChange={(e) => {
                startTransition(() => {
                  dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'text' }));
                });
              }}
            />
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Width"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.width}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'width' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) - 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) + 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Height"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.height || 0}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'height' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          fontSize="inherit"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) - 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) + 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position X"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.x}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'x' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) - 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) + 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position Y"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.y}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'y' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) - 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) + 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Font Size"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.fontSize}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'fontSize' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.fontSize) - 1,
                                  property: 'fontSize',
                                })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.fontSize) + 1,
                                  property: 'fontSize',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CirclePicker
                  width="100%"
                  circleSize={32}
                  color={shape?.fill}
                  onChangeComplete={(color) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: color.hex, property: 'fill' }));
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {shape?.type === 'image' && (
          <Box>


            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Width"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.width}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'width' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) - 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) + 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Height"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.height || 0}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'height' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          fontSize="inherit"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) - 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) + 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position X"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.x}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'x' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) - 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) + 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position Y"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.y}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'y' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) - 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) + 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Rotation"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.rotation || 0}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'rotation' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          fontSize="inherit"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.rotation) - 1,
                                  property: 'rotation',
                                })
                              );
                            });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.rotation) + 1,
                                  property: 'rotation',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {shape?.type === 'circle' && (
          <Box>


            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Width"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.width}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'width' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) - 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) + 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Height"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.height || 0}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'height' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          fontSize="inherit"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) - 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) + 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position X"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.x}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'x' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) - 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) + 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position Y"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.y}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'y' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) - 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) + 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CirclePicker
                  width="100%"
                  circleSize={32}
                  color={shape?.fill}
                  onChangeComplete={(color) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: color.hex, property: 'fill' }));
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {shape?.type === 'square' && (
          <Box>


            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Width"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.width}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'width' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) - 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.width) + 1,
                                  property: 'width',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Height"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.height || 0}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'height' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          fontSize="inherit"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) - 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({
                                  canvasId,
                                  shapeId: shape.id,
                                  value: Number(shape.height) + 1,
                                  property: 'height',
                                })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position X"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.x}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'x' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) - 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.x) + 1, property: 'x' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Position Y"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={shape?.y}
                  onChange={(e) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: e.target.value, property: 'y' }));
                    });
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) - 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            startTransition(() => {
                              dispatch(
                                editShape({ canvasId, shapeId: shape.id, value: Number(shape.y) + 1, property: 'y' })
                              );
                            });
                          }}
                        >
                          <Add fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CirclePicker
                  width="100%"
                  circleSize={32}
                  color={shape?.fill}
                  onChangeComplete={(color) => {
                    startTransition(() => {
                      dispatch(editShape({ canvasId, shapeId: shape.id, value: color.hex, property: 'fill' }));
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* {shape?.type !== 'text' && <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>} */}
      </AccordionDetails>
    </Accordion>
  );
}
