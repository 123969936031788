import { gql } from '@apollo/client';

export const CREATE_NORMAL_PRODUCT = gql`
  mutation CreateProduct(
    $name: String!,
    $thumbnailUrl: String!,
    $categoryId: String!,
    $storeId: String!,
    $description: String,
    $variants: [VariantInput!]!,
    $type: ProductType!
  ) {
  createProduct(
    name: $name
    thumbnail_url: $thumbnailUrl
    categoryId: $categoryId
    storeId: $storeId
    variants: $variants
    description: $description
    type: $type
  ) {
    id
    name
    __typename
  }
}
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $name: String!
    $colors: [String!]!
    $sizes: [String!]!
    $images: [String!]!
    $price: String!
    $categoryId: String!
    $storeId: String!
    $description: String
    $tags: [String]
    $sku: String
    $brandId: String
  ) {
    createProduct(
      name: $name
      colors: $colors
      sizes: $sizes
      images: $images
      price: $price
      categoryId: $categoryId
      storeId: $storeId
      description: $description
      tags: $tags
      sku: $sku
      brandId: $brandId
    ) {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $name: String!
    $colors: [String!]!
    $sizes: [String!]!
    $images: [String!]!
    $categoryId: String!
    $productId: String!
    $brandId: String
    $sku: String
    $tags: [String]
    $description: String
  ) {
    updateProduct(
      name: $name
      colors: $colors
      sizes: $sizes
      images: $images
      categoryId: $categoryId
      productId: $productId
      brandId: $brandId
      sku: $sku
      tags: $tags
      description: $description
    ) {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: String!) {
    deleteProduct(productId: $productId)
  }
`;


export const SYNC_CATEGORIES = gql`
mutation {
  syncCategories
}
`;
export const SYNC_PRODUCTS = gql`
mutation {
  syncProducts
}
`;

export const UPDATE_SERVICE = gql`
 mutation UpdateServiceProduct(
 $name: String!, 
 $thumbnailUrl: String!, 
 $categoryId: String!, 
 $variant: VariantInput!, 
 $productId: String!, 
 $description: String) {
  updateServiceProduct(name: $name, thumbnail_url: $thumbnailUrl, categoryId: $categoryId, variant: $variant, productId: $productId, description: $description) {
    id
  }
}
`;

export const UPDATE_NORMAL_PRODUCT = gql`
  mutation UpdateProduct(
    $name: String!
    $thumbnailUrl: String!
    $categoryId: String!
    $productId: String!
    $description: String!
    $availability_status: String!
    $price: String!
    $currency: String!

  ) {
    updateProduct(
      name: $name
      thumbnail_url: $thumbnailUrl
      categoryId: $categoryId
      productId: $productId
      description: $description
       availability_status: $availability_status
    price:  $price
    currency: $currency
    ) {
      id
    }
  }
`;

export const CREATE_NEW_VARIANT = gql`
  mutation CreateVariant($variant: VariantInput!, $productId: String!) {
    createVariant(variant: $variant, productId: $productId) {
      id
    }
  }
`;

export const GET_PRODUCT_VARIANTS = gql`
  query AvailableProductVariants($productId: String!) {
    availableProductVariants(productId: $productId) {
      id
      thumbnail_url
      name
      availability_status
      color
      preview_url
      size
      sku
      currency
      price
      Product {
        id
        name
        thumbnail
        description
        Category {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_VARIANT = gql`
  mutation ($variant: VariantInput!, $variantId: String!) {
    updateVariant(variant: $variant, variantId: $variantId) {
      id
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query ProductById($productId: String!) {
    productById(productId: $productId) {
      Tags {
        id
        name
      }
      Store {
        id
        name
        profilePic
        description
        createdAt
        coverPic
      }
      colors
      createdAt
      description
      id
      images
      name
      price
      sizes
      sku
      avgRating
      totalReview
      Stock {
        id
        createdAt
        color
        Sizes {
          available
          end
          price
          size
          stock
        }
      }
      Category {
        id
        name
      }
      Brand {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($storeId: String) {
    products(storeId: $storeId, withoutPagination: true) {
      id
      name
      type
      thumbnail
      Variants {
        id
      }
    }
  }
`;

export const GET_ALL_PRODUCT = gql`
  query products($storeId: String) {
    products(storeId: $storeId) {
      Brand {
        id
        name
      }
      Category {
        id
        name
      }
      Stock {
        id
        color
        Sizes {
          available
          end
          price
          size
          stock
        }
      }
      Store {
        id
        name
        profilePic
        description
        coverPic
        createdAt
      }
      Tags {
        id
        name
      }
      colors
      createdAt
      description
      NewArrival
      id
      images
      name
      price
      sizes
      sku
      avgRating
      totalReview
    }
  }
`;
