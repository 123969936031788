/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from '../../../redux/store';
import { addShape } from '../../../redux/slices/studio';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadSection() {
  const dispatch = useDispatch();
  const canvasId = useSelector((state) => state.studio.selectedView.type);
  const [files, setFiles] = React.useState([]);

  console.log(files, 'files')

  return (
    <>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        Upload files
        <VisuallyHiddenInput
          type="file"
          onChange={(event) => setFiles(Array.from(event.target.files))}
          multiple
        />
      </Button>
      {
        files?.map((preview, index) => {
          const src = URL.createObjectURL(preview)
          return (
            <img key={index} src={URL.createObjectURL(preview)} alt="" onClick={() => {
              dispatch(
                addShape({
                  canvasId,
                  shape: {
                    id: Date.now().toString(),
                    type: 'image',
                    src,
                    x: 20,
                    y: 20,
                    width: 200,
                    height: 10,
                    rotation:0,
                  },
                })
              );
            }} />
          )
        })
      }
    </>
  );
}