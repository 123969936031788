import { Box, Button, Grid } from '@mui/material';
import { Circle, Square, Star } from '@mui/icons-material';

import { useDispatch, useSelector } from '../../../redux/store';
import { addShape } from '../../../redux/slices/studio';


export default function TextSection() {
  const dispatch = useDispatch();
  const canvasId = useSelector((state) => state.studio.selectedView.type);


  return (
    <Box padding={2} key={canvasId}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          dispatch(
            addShape({
              canvasId,
              shape: {
                id: Date.now().toString(),
                type: 'text',
                text: 'Some text here',
                x: 20,
                y: 20,
                fontSize: 20,
                width: 200,
                height: 10,
                fill: '',
                fontFamily: 'Calibri',
              },
            })
          );
        }}
      >
        Add Text
      </Button>

      <Box marginTop={4}>
        <Grid>
          <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100px"
              height="100px"
              borderRadius="100%"
              backgroundColor="black"
              onClick={() => {
                dispatch(
                  addShape({
                    canvasId,
                    shape: {
                      id: Date.now().toString(),
                      type: 'circle',
                      x: 20,
                      y: 20,
                      width: 200,
                      height: 10,
                      fill: 'black',
                    },
                  })
                );
              }}
            />
            <Grid item xs={4} sm={3} md={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100px"
                height="100px"
                backgroundColor="black"
                onClick={() => {
                  dispatch(
                    addShape({
                      canvasId,
                      shape: {
                        id: Date.now().toString(),
                        type: 'square',
                        x: 20,
                        y: 20,
                        width: 200,
                        height: 10,
                        fill: 'black',
                      },
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
